export default {
  API_URL: "https://api-cbreau-stg.findyourspot.io",
    API_SERVICE_URL: "https://services.bookacorner.io",
  CLOUDFRONT_URL: "https://files.findyourspot.io",
  CLIENT: "cbre-au-stg",
  CLIENT_NAME: "CBREAU - Stg",
  GOOGLE_MAPS_API_KEY: "AIzaSyDSi0v-JQuubJRup1luQrF1Q-oiUeXpbXE",
  HOME_URL: "https://au-stg.findyourspot.io",
  UPLOAD_URL: "https://saxtyc5ci8.execute-api.us-east-1.amazonaws.com/prod",
  LOGGLY_KEY: "b523b1f3-6dd4-4be4-a10f-017f039f4de5",
  PROFORMA_URL: "https://bac-mediafiles.s3.amazonaws.com/all/proforma-cbre.html",
  HOME_HEADER_IMG_URL: "https://files.findyourspot.io/all/media/Fotos+de+portada+para+HOME/Foto+Centro+1.jpg",
  HOME_COMMENT_TEXT: "THE IMPLEMENTATION OF STAGING HAS BEEN A REVOLUTION RETAILERS AND OWNERS IN COMMERCIAL REAL ESTATE",
  HOME_COMMENT_AUTHOR: "Mr Staging",
  HOME_COMMENT_LINK: "https://bookacorner.com",
  HOME_COMMENT_IMAGE: "https://files.findyourspot.io/all/media/BAC/foto_branding.png",
  LOGO_LIGHT: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-light.png",
  LOGO_DARK: "https://files.findyourspot.io/all/media/CBRE/cbre-logo-dark.png",
  ADMIN_DEFAULT_LOCALE: "es-cbreau",
  TENANT_DEFAULT_LOCALE: "es-cbreau",
  ABOUT_US: "",
  HELP: "",
  TERMS_AND_CONDITIONS: "https://bookacorner.com/condiciones-generales",
  PRIVACY_POLICY: "https://files.bookacorner.net/all/media/Privacy Policy/CBRE Privacy Policy.pdf",
  SOCIAL_LINKS: {"facebook":"https://facebook.com","twitter":"twitter.com","instagram":"instagram.com"},
  ADVERTISING_IMG: "https://files.bookacorner.net/all/media/912de3e4-b8bc-4a9e-bbf4-f3c0d5ffb6ef.jpg",
  REVERB_APP_KEY: "uk7Iozh12TnWeys5XKQIuK6F",
  REVERB_HOST: "api-cbreau-stg.findyourspot.io",
  REVERB_AUTH_ENDPOINT: "https://api-cbreau-stg.findyourspot.io/api/broadcasting/auth",
  REVERB_PORT: 6001,
  FONT_API_URL: "https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&family=Raleway:wght@400;500;600;700&display=swap",
  LOCALES_SUPPORTED_ARRAY: ["es","en","fr","pt","it","es-cbreau","en-cbreau","pt-cbreau","fr-cbreau","it-cbreau"],
  MAPBOX_API_KEY: "pk.eyJ1IjoibWF4aW1pbGlhbm9iYWMiLCJhIjoiY2wxd2U5YXFrMGNzajNjbzE3MmZrNXljeiJ9.s4gzv9rbU479joxwhHVJkA",
  HOME_MAP_STYLE: "mapbox://styles/maximilianobac/cl27mkmhx002614ppe2ubwzq4",
  HOME_MAP_BOUNDS: [[112.62118121812236,-39.54617577107478],[153.2439249473218,-9.191036709104273]],
  MAPBOX_MARKER: "https://files.bookacorner.net/all/markers/mapbox-icon-CBRE.png",
    PDF_WORKER: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.12.313/build/pdf.worker.min.js",
  USER_MANUAL: "",
  COGNITO_URL: "https://bac-cbreau-stage.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=6uqnmf2sfj7n87qk95op683l9h&response_type=token&scope=email+openid+phone",
}
